<script>
import AnimatedTitles from './AnimatedTitles'

export default {
  components: {
    AnimatedTitles
  },
  data() {
    return {
      animations: []
    }
  },
  beforeDestroy() {
    this.animations.forEach((animation) => animation.kill && animation.kill())
  },
  mounted() {
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
  },
  methods: {
    setAnimation() {
      const elementIndex = [...this.$el.parentElement.childNodes].indexOf(this.$el)

      if (this.$el.parentElement.childNodes.length > elementIndex - 2) {
        this.animations.push(this.$root.gsapScrollTrigger({
          once: true,
          start: `bottom top+=15%`,
          end: `bottom bottom`,
          trigger: this.$el.previousElementSibling.previousElementSibling,
          onEnter: this.$refs.titles.animate
        }))

        this.animations.push(this.$root.gsapScrollTrigger({
          onEnter: () => {
            this.$el.classList.add(`email-me-visible`)
          },
          onUpdate: (e) => {
            if (e.progress === 0 && e.direction === -1) {
              this.$el.classList.remove(`email-me-visible`)
            }
          },
          start: `bottom bottom`,
          trigger: this.$el.previousElementSibling.previousElementSibling
        }))
      }      
    }
  }
}
</script>
