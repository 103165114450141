<script>
import AnimatedTitles from './AnimatedTitles'

export default {
  components: {
    AnimatedTitles
  },
  data() {
    return {
      animations: []
    }
  },
  beforeDestroy() {
    this.animations.forEach((animation) => animation.kill && animation.kill())
  },
  mounted() {
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
  },
  methods: {
    setAnimation() {
      const $columns = this.$el.querySelectorAll(`.get-in-touch-column`)
      const $introBio = document.querySelector(`.intro-bio`)

      if ($columns) {
        this.$root.$data.gsap.set($columns, { opacity: 0, y: '50%' })
        this.animations.push(this.$root.gsapScrollTriggerBatch($columns, {
          once: true,
          ease: `power1.inOut`,
          batchMax: () => $columns.length,
          onEnter: batch => this.$root.$data.gsap.to(batch, { opacity: 1, y: 0, stagger: { each: 0.15 }, overwrite: true }),
          start: `top-=35% bottom`
        }))
      }

      if ($introBio) {
        this.animations.push(this.$root.gsapFromTo($introBio, { y: '50%' }, {
          duration: 1,
          ease: `power1.inOut`,
          y: 0,
          scrollTrigger: {
            start: `top-=35% bottom`,
            trigger: $introBio
          }
        }))
      }
    }
  }
}
</script>
