<script>
export default {
  name: 'VideoPlayer',
  data() {
    return {
      animations: [],
      mounted: false,
      playing: false,
      timeout: 0
    }
  },
  props: {
    videoSrc: {
      type: String
    },
  },
  beforeDestroy() {
    this.animations.forEach((animation) => animation.kill())
  },
  mounted() {
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
    this.mounted = true
  },
  methods: {
    setAnimation() {
      const $container = this.$el.closest(`.works-list-work`)

      this.animations.push(this.$root.gsapScrollTrigger({
        onEnter: this.play,
        onEnterBack: this.play,
        onLeave: this.pause,
        onLeaveBack: this.pause,
        start: `top bottom`,
        end: `bottom top`,
        trigger: $container || this.$el
      }))
    },
    play() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.playing === false) {
          // console.log("playing", this.$el.childNodes[0].src)
          const playPromise = this.$el.play()

          if (playPromise !== undefined) {
            this.playing = true
            playPromise.then(() => {
            }).catch((error) => {
              this.playing = false
            })
          }
        }
      }, 50)
    },
    pause() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.playing === true) {
          // console.log("pausing", this.$el.childNodes[0].src)
          this.$el.pause()
          this.$el.currentTime = 0
          this.playing = false
        }
      }, 50)
    }
  }
}
</script>
