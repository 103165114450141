import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import MainNavigation from '../js/components/MainNavigation'
import PageTransition from '../js/components/PageTransition'
import RequestPortfolio from '../js/components/RequestPortfolio'
import AnimatedTitles from '../js/components/AnimatedTitles'
import ParallelTexts from '../js/components/ParallelTexts'
import WorksList from '../js/components/WorksList'
import EmailMe from '../js/components/EmailMe'
import GetInTouch from '../js/components/GetInTouch'
import VideoPlayer from '../js/components/VideoPlayer'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
require('../styles/site.scss')

require.context('../images/', true)

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)



let dynamic, fixed, $dynamic, $fixed, scrolledAmount, totalAssets = 0, imagesLoaded = 0, videosLoaded = 0, uniqueImages = []
const stylesRegEx = /<style([\s\S]+?)<\/style>/gim
const touchDevice = (navigator.maxTouchPoints || `ontouchstart` in document.documentElement)
const eventHub = new Vue()

function appendInlineStylesToHead(html) {
  const matches = html.match(stylesRegEx)
  const $inlineStyles = document.getElementById(`inline-styles`)

  if ($inlineStyles) {
    $inlineStyles.parentNode.removeChild($inlineStyles)
  }

  if (matches !== null && matches.length) {
    document.head.insertAdjacentHTML(`beforeend`, matches[0])
  }
}

function loadPage(url, pushState = false) {
  imagesLoaded = 0
  videosLoaded = 0
  totalAssets = 0

  eventHub.$emit(`navigation`, url)

  if (dynamic.$data.transition !== true) {
    dynamic.$data.transition = true
  }

  axios({
    url,
    method: `get`,
    params: {
      xhr: `xhr`
    }
  })
  .then((response) => {
    dynamic.$on(`animatedOut`, (e) => {
      dynamic.$data.animations.forEach((animation) => animation.kill())
      dynamic.$destroy()
    })

    dynamic.$on(`destroyed`, (e) => {
      Vue.nextTick().then(() => {
        const $dyn = document.getElementById(`dynamic`)

        $dynamic = document.createElement(`div`)
        $dynamic.id = `dynamic`
        $dynamic.innerHTML = response.data.replace(stylesRegEx, ``)

        const $links = $dynamic.querySelectorAll(`.work-container a`)

        if ($links) {
          $links.forEach(($link) => $link.target = `_blank`)
        }
        
        appendInlineStylesToHead(response.data)
        $dyn.parentNode.replaceChild($dynamic, $dyn)

        showLoadingProgress()

        fixed.preloadMedia().then(() => {
          const $workContainer = $dynamic.querySelector(`#work`)

          hideLoadingProgress()
          document.documentElement.classList.remove(`loading`)

          setTimeout(() => {
            document.getElementById(`dynamic`).scrollTo(0, 0)
            window.scrollTo(0, 0)
          }, 0)

          fixed.$refs.navigation.remount()
          fixed.$refs.requestPortfolio.remount()
          fixed.runGsapCallbacks()
          pushState && history.pushState({}, ``, url)

          fixed.$refs.navigation.setAnchors(url)

          if ($workContainer) {
            document.title = $workContainer.dataset.title
          } else {
            document.title = `clau`
          }

          setTimeout(() => {
            fixed.$refs.pageTransition.open()
          }, 500)
        })
      })
    })

    dynamic.$data.transition = false
  })
  .catch((error) => {
    document.location.href = `/`
  })
}

function onPopState(e) {
  if (!window.location.hash) {
    const scrolled = window.scrollY
    const doLoad = () => {
      setTimeout(() => {
        loadPage(window.location.pathname)
      }, 330)
    }

    document.documentElement.classList.add(`loading`)
    document.getElementById(`dynamic`).scrollTo(0, scrolled)
    fixed.$refs.requestPortfolio.setLoadingState()
    fixed.$refs.pageTransition.close(doLoad)
  }
}

function onClick(e) {
  let $link = e.target.href ? e.target : e.target.closest(`a`)

  if ($link && $link.href) {
    const $workContainer = $link.closest(`.work-container`)

    if (e.metaKey || $workContainer || $link.getAttribute(`target`) === `_blank` || $link.href.indexOf(`mailto`) !== -1) return true

    if ($link.href.indexOf(window.location.hostname) !== -1 && $link.href.indexOf(`#`) === -1) {
      const scrolled = window.scrollY
      const doLoad = () => {
        loadPage($link.href, true)
      }

      document.documentElement.classList.add(`loading`)
      document.getElementById(`dynamic`).scrollTo(0, scrolled)
      fixed.$refs.requestPortfolio.setLoadingState()
      fixed.$refs.pageTransition.close(doLoad)
    } else {
      const $anchorElement = document.getElementById($link.href.split(`#`)[1])

      if ($anchorElement) {
        gsap.to(window, {
          duration: 1.5,
          scrollTo: $anchorElement
        })
      }
    }
    e.preventDefault()
  }
}

function mountDynamic($el) {
  return new Vue({
    el: $el,
    components: {
      AnimatedTitles,
      EmailMe,
      GetInTouch,
      ParallelTexts,
      RequestPortfolio,
      WorksList,
      VideoPlayer
    },
    data() {
      return {
        gsapCallBacks: [],
        animating: false,
        animations: [],
        gsap,
        transition: true
      }
    },
    watch: {
      transition(value) {
        Vue.nextTick().then(() => {
          if (!this.animating) {
            this.$emit(`animatedOut`)
          }
        })
      }
    },
    mounted() {
      this.$nextTick().then(() => {
        setTimeout(() => {
          this.gsapCallBacks.push(this.initIntroAnimation)
          this.gsapCallBacks.forEach((callback) => callback && Vue.nextTick().then(callback))
          this.gsapCallBacks = []
          ScrollTrigger.refresh()
        }, 750)

        if (window.location.hash && window.scrollY === 0) {
          this.scrollToAnchor()
        }
      })
    },
    destroyed() {
      this.$emit(`destroyed`)
    },
    methods: {
      initIntroAnimation() {
        const $intro = document.querySelector(`.intro-text`)
        const $introMedia = document.querySelector(`.intro-media`)

        if ($intro && $introMedia) {
          this.animations.push(ScrollTrigger.create({
            scrub: true,
            trigger: $introMedia,
            start: `top-=${$intro.offsetHeight / 3}`, 
            end: self => `-=${$intro.offsetHeight}`,
            onEnter() {
              $intro.classList.add(`intro-text-hidden`)
            },
            onEnterBack() {
              $intro.classList.remove(`intro-text-hidden`)
            }
          }))
        }
      },
      scrollToAnchor() {
        const $anchorElement = document.querySelector(window.location.hash)

        if ($anchorElement) {
          gsap.to(window, {
            duration: 1.5,
            scrollTo: $anchorElement
          })
        }
      },
      gsapScrollTriggerBatch(targets, vars) {
        return ScrollTrigger.batch(targets, vars)
      },
      gsapScrollTrigger(config) {
        return ScrollTrigger.create(config)
      },
      gsapFromTo(targets, fromVars, toVars) {
        return gsap.fromTo(targets, fromVars, toVars)
      },
      beforeEnter(el) {
        el.style.opacity = 0.1
      },
      enter(el, done) {
        el.style.opacity = 1
        done()
      },
      leave(el, done) {
        this.animating = true
        el.style.opacity = 0.5

        setTimeout(() => {
          done()
          this.animating = false
          this.$emit(`animatedOut`)
        }, 0)
      }
    }
  })
}

function boot() {
  axios.defaults.headers.common[`X-CSRF-Token`] = document.querySelector(`meta[name=csrf-token]`).content

  $dynamic = document.getElementById(`dynamic`)
  $fixed = document.getElementById(`fixed`)

  fixed = new Vue({
    el: $fixed,
    components: {
      MainNavigation,
      PageTransition,
      RequestPortfolio
    },
    created() {
      eventHub.$on(`run`, (e) => {
        hideLoadingProgress()
        this.$refs.requestPortfolio.unsetLoadingState()
        this.run()
      })
      eventHub.$on(`navigation`, (url) => {
        this.$refs.navigation.onURLChange(url)
      })
    },
    data() {
      return {
        gsap,
        gsapCallBacks: [],
        transition: true
      }
    },
    mounted() {
      this.$nextTick().then(() => {
        this.$refs.requestPortfolio.setLoadingState()
        this.preloadMedia()
      })
    }, 
    methods: {
      run() {
        const that = this
        const doIntroAnimation = () => {
          that.$refs.navigation.introAnimation()
        }

        this.runGsapCallbacks()
        dynamic = mountDynamic($dynamic)
        dynamic.$nextTick().then(() => {
          this.$refs.pageTransition.open(doIntroAnimation)
          setTimeout(() => {
            ScrollTrigger.refresh()
          }, 750)
        })
      },
      runGsapCallbacks() {
        this.gsapCallBacks.forEach((callback) => callback && Vue.nextTick().then(callback))
        this.gsapCallBacks = []
      },
      gsapScrollTriggerBatch(targets, vars) {
        return ScrollTrigger.batch(targets, vars)
      },
      gsapScrollTrigger(config) {
        return ScrollTrigger.create(config)
      },
      gsapFromTo(targets, fromVars, toVars) {
        return gsap.fromTo(targets, fromVars, toVars)
      },
      onImageLoaded(resolve) {
        imagesLoaded++

        loadingProgress(resolve)
      },
      onVideoCanPlay(resolve) {
        videosLoaded++

        loadingProgress(resolve)
      },
      preloadImages($images, resolve) {
        Array.prototype.slice.call($images).forEach((image) => {
          if (uniqueImages.indexOf(image.src) === -1) {
            uniqueImages.push(image.src)

            if (image.complete) {
              this.onImageLoaded(resolve)
            } else {
              image.addEventListener(`load`, () => {
                this.onImageLoaded(resolve)
              }, false)
            }
          }
        })
      },
      preloadVideos($videos, resolve) {
        Array().forEach.call($videos, ($video) => {
          if ($video.readyState >= $video.HAVE_FUTURE_DATA) {
            this.onVideoCanPlay(resolve)
          } else {
            $video.addEventListener(`canplay`, () => {
              this.onVideoCanPlay(resolve)
            }, false)
          }
        })
      },
      preloadMedia() {
        const $images = document.querySelectorAll(`#dynamic img`)
        const $uniqueImages = Array.prototype.slice.call($images)
          .map(($image) => $image.src)
          .reduce((acc, char) => acc.includes(char) ? acc : [...acc, char], [])
        const $videos = touchDevice ? document.querySelectorAll(`.intro-media video`) : document.querySelectorAll(`#dynamic video`)

        uniqueImages = []
  
        if (totalAssets) {
          return new Promise((resolve, reject) => {
            this.preloadImages($images, resolve)
          })
        } else {
          totalAssets = $uniqueImages.length + $videos.length
          imagesLoaded = 0
          videosLoaded = 0
  
          return new Promise((resolve, reject) => {
            this.preloadVideos($videos, resolve)
            this.preloadImages($images, resolve)
          })
        }
      }
    }
  })

  document.addEventListener(`click`, onClick)
  window.addEventListener(`popstate`, onPopState)
}

if (`scrollRestoration` in window.history) {
  window.history.scrollRestoration = `manual`
}

function showLoadingProgress() {
  const $loading = document.querySelector(`.page-loading`)

  $loading.classList.remove(`loaded`)
}

function hideLoadingProgress() {
  const $loading = document.querySelector(`.page-loading div`)

  if (!$loading.parentElement.classList.contains(`loaded`)) {
    $loading.parentElement.classList.add(`loaded`)

    setTimeout(() => {
      $loading.innerText = ``
    }, 400)
  }
}

function loadingProgress(resolve) {
  const $loading = document.querySelector(`.page-loading div`)
  const progress = Math.ceil((imagesLoaded + videosLoaded) * 100 / totalAssets)

  $loading.innerText = `${progress}%`

  if (progress === 100) {
    hideLoadingProgress()
    eventHub.$emit(`run`)
    resolve && resolve()
  }  
}

window.addEventListener('DOMContentLoaded', () => {
  let $videos = []

  if (touchDevice) {
    $videos.push(document.querySelector(`.intro-media video`))
  } else {
    const $videosContainerMobile = document.querySelector(`.works-list-mobile video`)

    if ($videosContainerMobile && window.getComputedStyle($videosContainerMobile).display !== `none`) {
       $videos.push(...document.querySelectorAll(`.works-list-mobile video`))
    } else {
      $videos.push(...document.querySelectorAll(`.works-list-col video`))
    }
    $videos.push(...document.querySelectorAll(`#work video`))
  }

  $videos = $videos.filter(($video) => $video)

  const $images = document.querySelectorAll(`#dynamic img`)
  const $uniqueImages = Array.prototype.slice.call($images)
    .map(($image) => $image.src)
    .reduce((acc, char) => acc.includes(char) ? acc : [...acc, char], [])
  const $links = document.querySelectorAll(`.work-container a`)

  if ($links) {
    $links.forEach(($link) => $link.target = `_blank`)
  }

  totalAssets = $videos.length + $uniqueImages.length
  imagesLoaded = 0
  videosLoaded = 0

  if ($videos.length === 1 && typeof $videos[0].dataset.intro !== `undefined`) {
    const req = new XMLHttpRequest()

    req.open(`GET`, $videos[0].children[0].src, true)
    req.responseType = `blob`

    req.addEventListener(`loadend`, function() {
      $videos[0].children[0].src = URL.createObjectURL(this.response)
      videosLoaded++
      loadingProgress()
    })

    req.onerror = () =>  {
      videosLoaded++
      loadingProgress()
    }

    req.send()
  } else if ($videos.length) {
    Array().forEach.call($videos, ($video) => {
      if ($video.readyState >= $video.HAVE_FUTURE_DATA) {
        videosLoaded++
        loadingProgress()
      } else {
        $video.addEventListener(`canplay`, () => {
          videosLoaded++
          loadingProgress()
        }, false)
      }
    })
  }
  boot()
})
