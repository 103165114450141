<template>
  <div ref="dotCursor" :class="'dot-cursor ' + this.type">
    <div v-html="this.text"></div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  data() {
    return {
      firstTime: true,
      animationDelay: 0.3,
      touchDevice: (navigator.maxTouchPoints || `ontouchstart` in document.documentElement)
    }
  },
  beforeDestroy() {
    const $container = document.querySelector(this.container)

    if (!this.touchDevice && $container) {
      this.$el.classList.remove(`dot-cursor-mounted`)
      $container.classList.remove(`no-cursor`)
      $container.removeEventListener(`mousemove`, this.onMouseMove)
      $container.removeEventListener(`mouseenter`, this.onMouseEnter)
      $container.removeEventListener(`mouseleave`, this.onMouseLeave)
    }
  },
  mounted() {
    const $container = document.querySelector(this.container)

    if (!this.touchDevice && $container) {
      this.$el.classList.add(`dot-cursor-mounted`)
      $container.classList.add(`no-cursor`)
      $container.addEventListener(`mousemove`, this.onMouseMove)
      $container.addEventListener(`mouseenter`, this.onMouseEnter)
      $container.addEventListener(`mouseleave`, this.onMouseLeave)
    }
  },
  methods: {
    onMouseMove(e) {
      const cursor = this.$refs.dotCursor.getBoundingClientRect()
      const rect = e.target.getBoundingClientRect()
      const bodyRect = document.body.getBoundingClientRect()
      const offset = rect.top - bodyRect.top


      let x = Math.round(e.clientX - rect.left - (cursor.width / 1.31416))
      let y = Math.round(e.clientY - rect.top - (cursor.height / 1.31416))

      gsap.to(this.$refs.dotCursor, this.firstTime ? 0 : this.animationDelay, { x, y })

      this.firstTime = false
    },
    onMouseEnter(e) {
      gsap.to(this.$refs.dotCursor, this.animationDelay, { scale: 1 })
    },
    onMouseLeave(e) {
      gsap.to(this.$refs.dotCursor, this.animationDelay, { scale: 0 })
    }
  },
  props: {
    container: String,
    type: {
      type: String,
      default: `dot-cursor-white`
    },
    text: String
  }  
}
</script>

<style lang="scss" scoped>
  .dot-cursor {
    align-items: center;
    border-radius: 75px;
    display: none;
    height: 150px;
    justify-content: center;
    mix-blend-mode: difference;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
    width: 150px;
    z-index: 2;

    &-mounted {
      display: flex;
    }

    &-black {
      background-color: #8E93FF;

      > div {
        color: #1A1A1A;
      }
    }

    &-white {
      background-color: #fff;

      > div {
        color: #1A1A1A;
      }
    }

    > div {
      font-family: "Suisse Intl clauworks";
      font-size: 20px;
      text-align: center;
    }
  }
</style>