<script>
export default {
  data() {
    return {
      animations: []
    }
  },
  beforeDestroy() {
    this.destroyAnimations()
  },
  mounted() {
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
    this.setAnchors(document.location.href)
  },
  methods: {
    setAnchors(url) {
      let $anchors = document.querySelectorAll(`.main-navigation a`)

      if (url && $anchors.length) {
        const urlParts = url.replace(/^https?:\/\//, ``).split(`/`).filter((part) => part)

        if (urlParts.length === 1) {
          if ($anchors.length) {
            $anchors[1].href = `#about`
          }
        } else if (urlParts.length) {
          if (urlParts[1].toLowerCase() === `works`) {

            if ($anchors.length) {
              $anchors[1].href = `#intro`
            }
          }
        } else {
          if ($anchors.length) {
            $anchors[1].href = `#about`
          }
        }
      }
    },
    introAnimation() {
      this.$el.querySelectorAll(`li`).forEach(($li, index) => {
        setTimeout(() => {
          $li.classList.add(`active`)
        }, (index + 1) * 100);
      })
    },
    setAnimation() {
      this.setColorAnimations()
    },
    setColorAnimations() {
      if (document.querySelector(`.main-navigation`)) {
        const $menuBlackSections = document.querySelectorAll(`[data-menu-black]`)
        const $sections = [...$menuBlackSections].map(($section) => [...$section.parentElement.children].indexOf($section)).reduce((r, n) => {
          const lastSubArray = r[r.length - 1]
          
          if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
            r.push([])
          } 
          
          r[r.length - 1].push(n)
          
          return r
        }, [])

        $sections.forEach((section, index) => {
          this.animations.push(this.$root.gsapScrollTrigger({
            start: `top ${section[0] !== 0 ? 'top' : 'bottom'}`,
            end: `bottom top`,
            endTrigger: $menuBlackSections[0].parentElement.children[section[1] || section[0]],
            trigger: $menuBlackSections[0].parentElement.children[section[0]],
            onEnter: this.navigationSetBlack,
            onEnterBack: this.navigationSetBlack,
            onLeave: this.navigationUnsetBlack,
            onLeaveBack: this.navigationUnsetBlack
          }))
        })
      }
    },
    destroyAnimations() {
      this.animations.forEach((animation) => animation.kill && animation.kill())
    },
    remount() {
      this.destroyAnimations()
      this.navigationUnsetBlack()
      this.$root.$data.gsapCallBacks.push(this.setColorAnimations())
    },
    navigationSetBlack() {
      document.querySelector(`.main-navigation`).classList.add(`main-navigation-black`)
    },
    navigationUnsetBlack() {
      document.querySelector(`.main-navigation`).classList.remove(`main-navigation-black`)
    },
    onURLChange(url) {
      let path

      try {
        path = new URL(url).pathname
      } catch (e) {
        path = url
      }

      this.$refs.navigationHome.hidden = path === `/` || (this.$refs.navigationHome.dataset.home !== `/` && path.indexOf(this.$refs.navigationHome.dataset.home) !== -1)
    }
  }
}
</script>
