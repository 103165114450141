<template>
  <a href="mailto:hello@clauaskee.com" class="smiley-wrapper" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
    <span>E-mail me to request my design &amp; UI/UX portfolio</span>
    <div :class="textClass" ref="text">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="text text-request-portfolio" v-bind:src="require('svgs/text-request-portfolio.svg')">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="text text-lets-work" v-bind:src="require('svgs/text-lets-work.svg')">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="text text-infinity" v-bind:src="require('svgs/text-infinity.svg')">
    </div>
    <div :class="smileyClass">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="smiley smiley-sad" v-bind:src="require('svgs/smiley-sad.svg')">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="smiley smiley-xx" v-bind:src="require('svgs/smiley-xx.svg')">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="smiley smiley-happy" v-bind:src="require('svgs/smiley-happy.svg')">
      <img alt=">E-mail me to request my design &amp; UI/UX portfolio" class="smiley smiley-xx-loading" v-bind:src="require('svgs/smiley-xx-loading.svg')">
    </div>
  </a>
</template>

<script>
export default {
  data() {
    return {
      animations: [],
      smileyClass: ``,
      previousTextClass: ``,
      textClass: ``
    }
  },
  beforeDestroy() {
    this.destroyAnimations()
  },
  mounted() {
    this.smileyClass = this.smiley
    this.textClass = this.text
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
  },
  props: {
    smiley: {
      type: String,
      default: `smiley-container smiley-container-xx`
    },
    text: {
      type: String,
      default: `text-container text-container-request-portfolio`
    }
  },
  methods: {
    onMouseOver() {
      this.smileyClass = `smiley-container smiley-container-happy`
      this.$root.$data.gsap.to(this.$el, { duration: 0.25, scale: 1.08 })
    },
    onMouseLeave() {
      this.smileyClass = this.smiley
      this.$root.$data.gsap.to(this.$el, { duration: 0.25, scale: 1 })
    },
    rotateBack() {
      this.$root.$data.gsap.to(this.$refs.text, { duration: 0.75, rotation: 0 })
    },
    showSmiley(name) {
      this.smileyClass = `smiley-container smiley-container-${name}`
    },
    setLoadingState() {
      this.$el.classList.add(`loading`)
      // this.previousTextClass = this.textClass

      this.showSmiley(`xx-loading`)
      // this.textClass = `text-container text-container-infinity`
    },
    unsetLoadingState() {
      this.$el.classList.remove(`loading`)
      this.showSmiley(this.smiley)
      // this.textClass = this.previousTextClass
    },
    setAnimation() {
      setTimeout(() => {
        this.$el.classList.add(`active`)
        setTimeout(() => {
          this.$el.classList.add(`mounted`)
          this.setComponentAnimations()
        }, 800)
      }, 500)
    },
    setComponentAnimations() {
      this.animations.push(this.$root.gsapFromTo(this.$refs.text, { rotation: 0 }, {
        rotation: 360,
        scrollTrigger: {
          start: `top top`,
          trigger: `#dynamic`, 
          scrub: 0.5,
          end() {
            const height = Math.max(
              document.body.scrollHeight,
              document.body.offsetHeight,
              document.documentElement.clientHeight,
              document.documentElement.scrollHeight,
              document.documentElement.offsetHeight
            )

            return height - window.innerHeight
          }
        }
      }))

      const $emailMe = document.querySelector(`.email-me`)

      if ($emailMe) {
        const elementIndex = [...$emailMe.parentElement.childNodes].indexOf($emailMe)

        if ($emailMe.parentElement.childNodes.length > elementIndex - 2) {
          setTimeout(() => {
            const width = this.$el.offsetWidth
            let y, end

            if (width === 100) {
              y = `-40px`
              end = `40`
            } else {
              y = `-80px`
              end = `80`
            }

            this.animations.push(this.$root.gsapFromTo(this.$el, { y: 0 }, {
              y,
              scrollTrigger: {
                end: `bottom bottom-=${end}`,
                onEnter: this.setReversed,
                onLeaveBack: this.unsetReversed,
                scrub: 0.4,
                start: `bottom bottom`,
                trigger: $emailMe.previousElementSibling.previousElementSibling
              }
            }))
          }, 500);
        }
      }      
    },
    unsetReversed() {
      this.$el.classList.remove(`reversed`)
    },
    setReversed() {
      this.$el.classList.add(`reversed`)
    },
    destroyAnimations() {
      this.animations.forEach((animation) => {
        animation.kill && animation.kill()
      })
    },
    remount() {
      this.destroyAnimations()
      this.$root.$data.gsapCallBacks.push(this.setComponentAnimations())
    }
  }
}
</script>

<style lang="scss" scoped>
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .smiley-wrapper {
    align-items: center;
    background-color: #47F654;
    border-radius: 50px;
    bottom: 30px;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: center;
    left: 4.5vw;
    max-height: 150px;
    max-width: 150px;
    opacity: 0;
    position: fixed;
    transform: translateX(calc(calc(100% + 4.5vw) * -1)) rotate(-360deg);
    transition: opacity 500ms linear, transform 750ms cubic-bezier(0.5, 0.25, 0, 1), background-color 200ms ease;
    width: 100px;
    z-index: 9999999;

    @media (min-width: 768px) {
      border-radius: 75px;
      height: 6.8vw;
      width: 6.8vw;
      min-height: 150px;
      min-width: 150px;
    }

    span {
      text-indent: -99999px;
    }

    &.loading {
      .text-container .text {
        animation: spin 5s linear infinite;
      }
    }

    .text-container {
      height: 85%;
      position: absolute;
      width: 85%;

      .text {
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &-request-portfolio {
        .text-infinity,
        .text-lets-work {
          visibility: hidden;
        }
      }

      &-lets-work {
        .text-infinity,
        .text-request-portfolio {
          visibility: hidden;
        }
      }

      &-infinity {
        .text-lets-work,
        .text-request-portfolio {
          visibility: hidden;
        }
      }
    }

    .smiley-container {
      height: 40%;
      position: absolute;
      width: 40%;

      .smiley {
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &-happy {
        .smiley-sad,
        .smiley-xx-loading,
        .smiley-xx {
          visibility: hidden;
        }
      }
      &-sad {
        .smiley-xx,
        .smiley-xx-loading,
        .smiley-happy {
          visibility: hidden;
        }
      }
      &-xx {
        .smiley-happy,
        .smiley-xx-loading,
        .smiley-sad {
          visibility: hidden;
        }
      }
      &-xx-loading {
        .smiley-happy,
        .smiley-xx,
        .smiley-sad {
          visibility: hidden;
        }
      }
    }

    &.active {
      opacity: 1;
      transform: translateX(0) rotate(0);
    }

    &.mounted {
      transition: opacity 500ms linear, background-color 200ms ease;
    }

    &:hover {
      background-color: #8E93FF;
    }

    &.reversed {
      background-color: #8E93FF;

      &:hover {
        background-color: #47F654;
      }
    }
  }
</style>