<script>
export default {
  data() {
    return {
      animations: []
    }
  },
  beforeDestroy() {
    this.animations.forEach((animation) => animation.kill && animation.kill())
  },
  mounted() {
    this.$root.$data.gsap.utils.toArray(`.intro-skills h2`).forEach((text, index) => {
      const [x, xEnd] = (index % 2) ? [`-100%`, `50%`] : [`100%`, `-50%`]

      this.animations.push(this.$root.gsapFromTo(text, { x }, {
        x: xEnd,
        scrollTrigger: {
          trigger: text,
          start: `top bottom`,
          scrub: 0.5
        }
      }))
    })
  }
}
</script>
