<script>
export default {
  data() {
    return {
      delay: 800
    }
  },
  methods: {
    close(callback) {
      this.$el.classList.remove(`animate-in`)
      this.$el.classList.add(`animate-out`)

      callback && setTimeout(callback, this.delay)
    },
    open(callback) {
      this.$el.classList.remove(`animate-out`)
      this.$el.classList.add(`animate-in`)

      callback && setTimeout(callback, this.delay)
    }
  }
}
</script>
