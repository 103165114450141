<script>
import VideoPlayer from './VideoPlayer'

export default {
  data() {
    return {
      animations: []
    }
  },
  components: {
    VideoPlayer
  },
  beforeDestroy() {
    this.animations.forEach((animation) => animation.kill && animation.kill())
  },
  mounted() {
    this.$root.$data.gsapCallBacks.push(this.setAnimation)
  },
  methods: {
    setAnimation() {
      this.$root.$data.gsap.utils.toArray(`.works-list-work`).forEach((work) => {
        const [y, yEnd] = [`50%`, `0%`]

        this.animations.push(this.$root.gsapFromTo(work.firstChild, { y }, {
          y: yEnd,
          duration: 0.5,
          ease: `power1.inOut`,
          scrollTrigger: {
            start: `top 85%`,
            trigger: work
          }
        }))
      })
    }
  }
}
</script>
